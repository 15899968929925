import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    pathologiesList: [],
    newNotificationsNumber: null,
  },
  getters: {
    getPathologiesList: (state) => state.pathologiesList,
    newNotificationsNumber: (state) => state.newNotificationsNumber,
  },
  mutations: {
    setPathologiesList: (state, list) => {
      state.pathologiesList = list
    },
    setNewNotificationsNumber: (state, newNotificationsNumber) => {
      state.newNotificationsNumber = newNotificationsNumber
    },
  },
  actions: {
    fetchPathologiesList({ commit }) {
      return Vue.$http('general/clinical/getPathologyList')
        .then(({ data }) => {
          const { pathologies } = data

          commit('setPathologiesList', pathologies)

          return pathologies
        })
        .catch((e) => {
          console.log(e)
          console.log('Error fetching pathologies list')
        })
    },
    fetchNotificationsStats: async ({ commit }) => {
      try {
        const { data } = await Vue.$http('notifications/fetchNotificationsStats')

        commit('setNewNotificationsNumber', data.unread_notifications)
      } catch (e) {
        console.error('It was not possible to fetch notifications stats.')
      }
    },
  },
}
