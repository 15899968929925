<template>
  <base-feedback-screen-structure v-show="showBaseFeedbackScreen" :animated="animated" :center-content="centerContent" :transparent="transparent">
    <slot></slot>
  </base-feedback-screen-structure>
</template>

<script>
import BaseFeedbackScreenStructure from '@/components/feedbacks/boilerPlate/BaseFeedbackScreenStructure.vue'

export default {
  name: 'BaseFeedbackScreen',
  components: {
    BaseFeedbackScreenStructure,
  },
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    centerContent: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showBaseFeedbackScreen: false,
    }
  },
  mounted() {
    this.showBaseFeedbackScreen = true
  },
  destroyed() {
    this.showBaseFeedbackScreen = false
  },
}
</script>
