export default {
  options: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/mind/program-focus`
    },
  },
  getCurrent: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/mind/members/${programUuid}/member-program`
    },
  },
  update: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/mind/members/member-program`
    },
  },
}
