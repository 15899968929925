import StorageInterface from '@/libs/storageInterface'

/**
 * Obj keys used to reference what preference to manage
 */
const apiMappings = {
  dismissRecommendationsTips: {
    group: 'portal_popup',
    keys: 'show_prescription_recommendation_popup',
  },
  dismissManualExerciseOrderTips: {
    group: 'portal_popup',
    keys: 'show_manual_exercise_order_helper',
  },
  dismissWeightsTips: {
    group: 'portal_popup',
    keys: 'hide_weight_prescription_popup',
  },
  dismissOnboardingProfileWarning: {
    group: 'portal_popup',
    keys: 'hide_missing_onboarding_info_popup',
  },
}

const storage = new StorageInterface(sessionStorage)
const USER_PREFERENCE_KEY = 'userPreferences'

export default {
  namespaced: true,
  actions: {
    async getPreference({ dispatch }, key) {
      // Checking if store key as any info locally
      const localValue = await dispatch('getPreferenceLocally', key)

      if (localValue !== undefined && localValue !== null) {
        return localValue
      }

      // When undefined in store, fetch from remote
      return dispatch('fetchPreference', key)
    },
    async setPreference({ dispatch }, { key, value, remote = false }) {
      if (remote) {
        await dispatch('setPreferenceOnRemote', { key, value })

        return
      }
      dispatch('setPreferenceLocally', { key, value })
    },
    clearPreferences() {
      storage.delete(USER_PREFERENCE_KEY)
    },
    async fetchPreference({ dispatch }, key) {
      const apiRef = apiMappings[key]

      if (!apiRef) {
        return undefined
      }

      try {
        const data = await dispatch('configs/fetchUserConfig', { keys: apiRef.keys, group: apiRef.group }, { root: true })
        const preferenceValue = (Array.isArray(data) && data.length > 0) ? data[0].value : null

        dispatch('setPreferenceLocally', { key, value: preferenceValue })

        return preferenceValue
      } catch (error) {
        console.error('ERROR fetchPreference', error)

        return undefined
      }
    },
    async setPreferenceOnRemote({ dispatch }, { key, value }) {
      const apiRef = apiMappings[key]

      // Setting preference on remote
      try {
        await dispatch(
          'configs/setUserConfig',
          { group: apiRef.group, key: apiRef.keys, value },
          { root: true },
        )
        dispatch('setPreferenceLocally', { key, value })
      } catch (error) {
        console.error('ERROR setPreferenceOnRemote', error)
      }
    },
    getPreferenceLocally(_, key) {
      const preferences = storage.read(USER_PREFERENCE_KEY)

      return preferences && preferences[key]
    },
    setPreferenceLocally(_, { key, value }) {
      const preferenceKey = Object.prototype.hasOwnProperty.call(key, 'key') ? key.key : key
      const preferences = storage.read(USER_PREFERENCE_KEY) || {}
      const newPreferences = Object.assign({}, preferences, { [preferenceKey]: value })

      storage.write(USER_PREFERENCE_KEY, newPreferences)
    },
  },
}
