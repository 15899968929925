import { CONFIG } from '@/scripts/app-configs/constants'

export default {
  clinical: {
    getPathologyList: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'api/v1.1/pathologies'
      },
    },
  },
  user: {
    getUserPhoto: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$meta: {
        mock: (_, userId) => `mocks/endpoints/general/getUserPhoto/${userId}.json`,
      },
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, userId) {
        return `api/v1.1/users/${userId}/image`
      },
    },
    setUserPhoto: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, userId) {
        return `api/v1.1/users/${userId}/image`
      },
    },
    getTherapistInfo: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, userId) {
        return `api/v1/therapist/${userId}/information`
      },
    },
    setTherapistInfo: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, userId) {
        return `api/v1/therapist/${userId}/information`
      },
    },
  },
  getAssets: {
    api: {
      url: 'default',
    },
    $$meta: {
      mock: () => 'mocks/endpoints/general/getAssets.json',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { version }) {
      const url = `${CONFIG.assetsApiUrl}/v1/version`
      const queryParams = new URLSearchParams({ version })

      return `${url}?${queryParams}`
    },
  },
  getInstructionsOfUse: {
    api: {
      url: 'default',
    },
    $$meta: {
      mock: () => 'mocks/endpoints/general/getAssets.json',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
      responseType: 'blob',
    },
    $$makeUrl(_, unit) {
      const url = 'therapist/v1/static/instructions_of_use'
      const queryParams = new URLSearchParams()

      if (unit) {
        queryParams.set('unit', unit)
      }

      console.log(queryParams)

      return `${url}${queryParams.size ? `?${queryParams}` : ''}`
    },
  },
  discoverChannels: {
    api: {
      url: 'default',
      version: 2,
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { memberAccountUuid, unit }) {
      return `therapist/v${configs.api.version}/chat/member/${memberAccountUuid}/channel/${unit}`
    },
  },
}
