<template>
  <div class="quick-action-button">
    <sword-tooltip placement="top" theme="black" text-align="center">
      <sword-button-wuk
        :icon="icon"
        theme="white"
        class="quick-action-button__main-trigger"
        :class="{ '--options-open': showOptions}"
        round
        :aria-label="`click to ${$t(tooltip)}`"
        @click.native="handleMainActionClick"
      />
      <template #content>
        <p class="-mxy_0"> {{ $t(tooltip) }} </p>
      </template>
    </sword-tooltip>

    <div
      v-if="showOptions"
      :key="`${actionKey}-options`"
      v-on-click-outside="closeOptions"
      class="quick-action-button__options"
      :class="`${actionKey}-options`"
    >
      <sword-list-wuk key="send-link-options" :options="options" align-left @click="optionHandler" />
    </div>

  </div>
</template>

<script>
import { ref } from 'vue'

import { vOnClickOutside as onClickOutside } from '@vueuse/components'
import SwordTooltip from '@ui-kit/components/tooltips/SwordTooltip.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'
import SwordListWuk from '@ui-kit/components/menus/List.vue'

export default {
  name: 'QuickAction',
  directives: { onClickOutside },
  components: {
    SwordTooltip,
    SwordButtonWuk,
    SwordListWuk,
  },
  props: {
    actionKey: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  emits: ['action'],
  setup(props, { emit }) {
    const showOptions = ref(false)

    const closeOptions = () => {
      showOptions.value = false
    }

    const emitAction = (value) => {
      emit('action', value)
      closeOptions()
    }

    const handleMainActionClick = () => {
      if (props.options?.length) {
        showOptions.value = !showOptions.value

        return
      }
      emitAction(props.actionKey)
    }

    const optionHandler = (opt) => {
      emitAction(opt.optValue)
    }

    return {
      showOptions,
      closeOptions,
      emitAction,
      handleMainActionClick,
      optionHandler,
    }
  },
}
</script>

<style lang="scss" scoped>

.quick-action-button {
  position: relative;
  display: inline-block;

  ::v-deep {
    .info-tooltip-container {
      height: initial;
    }
  }

  &__main-trigger {
    &.sword-button-wuk.--medium.round {
      margin: 0;

      ::v-deep {
        .sword-icon.svg-icon {
          width: calc($button-round-icon-base-size-wuk * 1.30);
          height: calc($button-round-icon-base-size-wuk * 1.30);
        }
      }
    }
  }

  &__options {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    transform: translateY(100%);
    padding: 0.5rem 0;
    min-width: 20rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 10px 0 $shadow-wuk;
    background-color: $color-white;
    z-index: 1;
  }
}

</style>
